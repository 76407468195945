<template>
  <!--begin::Task Internal Listing-->
  <v-container fluid>
    <ListingTable :rowData.sync="attachments" :columnCount="4">
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th" width="100"></th>
            <th class="simple-table-th" width="200">Name</th>
            <th
              class="simple-table-th"
              width="200"
              v-if="
                type == 'product' || type == 'property' || type == 'package'
              "
            >
              Primary
            </th>
            <th class="simple-table-th" width="200">Action</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="attachments.length > 0">
            <tr :key="index" v-for="(document, index) in attachments">
              <td
                class="simple-table-td"
                v-on:click="
                  downloadAPIFile(document.id, document.file.name, index)
                "
              >
                <div class="custom-mime-content text-center">
                  <template v-if="isValidImage(document.file.path)">
                    <img
                      height="100px"
                      width="100px"
                      :src="document.file.url"
                      :alt="document.file.name"
                    />
                  </template>
                  <template v-else>
                    <span class="svg-icon svg-icon-md custom-mime-icon mr-4">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        :src="
                          $assetURL(
                            'media/mime/' +
                              getFileExtension(document.file.path) +
                              '.svg'
                          )
                        "
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </template>
                </div>
              </td>
              <td class="simple-table-td">
                {{ document.name }}
              </td>
              <td
                class="simple-table-td"
                width="100"
                v-if="
                  type == 'product' || type == 'property' || type == 'package'
                "
              >
                <template v-if="document && document.primary">
                  <v-chip color="cyan lighten-5" label>
                    <i
                      class="flaticon2-correct text-success font-size-h5 mr-2"
                    ></i>
                    Primary
                  </v-chip>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mx-2" v-bind="attrs" v-on="on"
                        >mdi-progress-question</v-icon
                      >
                    </template>
                    <span
                      >This image will be displayed in<br />all your
                      transactions for this item.</span
                    >
                  </v-tooltip>
                </template>
                <template v-else
                  ><v-btn
                    :disabled="pageLoading || isPageLoading"
                    :loading="primaryLoading"
                    v-on:click="setAsPrimary(document.id)"
                    text
                    small
                    color="cyan"
                    class="text-white font-size-14"
                    >Set as primary</v-btn
                  ></template
                >
              </td>
              <td class="simple-table-td" width="100">
                <template v-if="getPermission('attachment:delete')">
                  <v-btn
                    class="mx-2 custom-bold-button"
                    color="red lighten-1"
                    small
                    :loading="isDeleting(index)"
                    :disabled="primaryLoader || isDeleting(index)"
                    icon
                    v-on:click="deleteAPIFile(document.id, index, attachments)"
                    ><v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </td>
            </tr>
          </template>
          <tr v-else>
            <td
              :colspan="
                type == 'product' || type == 'property' || type == 'package'
                  ? 4
                  : 3
              "
            >
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no Attachment at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
  </v-container>
  <!--end::Task Internal Listing-->
</template>

<script>
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
//import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import { PATCH } from "@/core/services/store/request.module";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, FileManagerMixin],
  name: "attachment-view",
  data() {
    return {
      dates: [],
      statusList: [],
      dataRows: [],
      dataLoading: true,
      customerList: [],
      filter: {
        status: "all",
        search: null,
      },
      timeout: null,
      timeoutLimit: 500,
    };
  },
  props: {
    attachments: {
      type: Array,
      default: new Array(),
    },
    type: {
      type: String,
      default: "",
    },
    parent: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    attachments: {
      deep: true,
      immediate: true,
      handler() {
        console.log({ attachments: this.attachments });
      },
    },
  },
  components: {
    ListingTable,
  },
  methods: {
    setAsPrimary(id) {
      const _this = this;
      _this.pageLoading = true;
      _this.primaryLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: `${_this.type}/${_this.parent}/image/${id}/primary`,
        })
        .then(() => {
          this.$emit("refresh:attachment");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.primaryLoading = false;
        });
    },
    isValidImage(file_url) {
      try {
        const _this = this;
        let validImageExtension = ["png", "jpg", "jpeg"];
        let fileExtention = _this.lodash.last(file_url.split("."));
        return _this.lodash.includes(
          validImageExtension,
          fileExtention.toLowerCase()
        );
      } catch (error) {
        this.logError(error);
        return false;
      }
    },
  },
};
</script>
