<template>
  <!--begin::Payment Internal Listing-->
  <v-container fluid>
    <v-container>
      <v-row>
        <v-col md="2">
          <v-select
            :disabled="dataLoading"
            :loading="dataLoading"
            :items="paymentTypeList"
            v-model="filter.payment_type"
            hide-details
            flat
            solo
            dense
            label="Payment Type"
            color="cyan"
            item-color="cyan"
            class="pt-0 mt-0"
            item-text="text"
            item-value="value"
            v-on:change="getAllPayments"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-select>
        </v-col>
        <v-col md="2">
          <v-select
            :disabled="dataLoading"
            :loading="dataLoading"
            :items="invoiceList"
            v-model="filter.invoice"
            hide-details
            flat
            solo
            dense
            label="Invoice"
            color="cyan"
            item-color="cyan"
            class="pt-0 mt-0"
            item-text="barcode"
            item-value="id"
            v-on:change="getAllPayments"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.barcode"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-select>
        </v-col>
        <v-col md="2">
          <v-text-field
            v-model.trim="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            label="Search"
            hide-details
            solo
            :disabled="dataLoading"
            :loading="dataLoading"
            color="cyan"
            @keydown.enter="getAllPayments"
            @keydown.tab="getAllPayments"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-menu
            v-model="datePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                flat
                label="Dates Range"
                hide-details
                solo
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click:clear="clearFilter('dates')"
                clearable
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :value="formattedDate"
                color="cyan"
              ></v-text-field>
            </template>
            <v-date-picker
              color="cyan"
              range
              :disabled="dataLoading"
              :loading="dataLoading"
              v-on:change="getAllPayments"
              v-model="dates"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="2">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click="getAllPayments"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
    <ListingTable
      :columnCount="5"
      :dataLoading.sync="dataLoading"
      :rowData.sync="dataRows"
    >
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th" width="100">Payment #</th>
            <th class="simple-table-th" width="200">Dates</th>
            <!-- <th class="simple-table-th" width="200">Customer Info</th> -->
            <th class="simple-table-th" width="200">Invoice</th>
            <th class="simple-table-th" width="200">Payment Mode</th>
            <th class="simple-table-th" width="200">Amount</th>
            <th class="simple-table-th" width="200">Created Time</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows.length > 0">
            <tr link :key="index" v-for="(row, index) in dataRows">
              <td class="simple-table-td">
                <Barcode
                  route="payment.detail"
                  :barcode="row.barcode"
                  :id="row.id"
                ></Barcode>
              </td>
              <td class="simple-table-td">
                <div class="payment-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis" v-if="row.payment_date">
                    <b>Payment: </b> {{ formatDate(row.payment_date) }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis" v-if="row.due_date">
                    <b>Invoice Due: </b>
                    {{ formatDate(row.invoice.due_date) }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis" v-if="row.sent_date">
                    <b>Invoice Sent: </b>
                    {{ formatDate(row.invoice.sent_date) }}
                  </p>
                </div>
              </td>
              <!-- <td class="simple-table-td" width="100">
                <div class="payment-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Name: </b>
                    {{
                      row.attention
                        ? row.attention
                        : row.contact_person
                        ? row.contact_person.display_name
                        : ""
                    }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis" v-if="row.customer">
                    <b>Company: </b> {{ row.customer.display_name }}
                  </p>
                  <p
                    class="m-0 custom-nowrap-ellipsis"
                    v-if="row.contact_person"
                  >
                    <b>Email: </b> {{ row.contact_person.primary_email }}
                  </p>
                  <p
                    class="m-0 custom-nowrap-ellipsis"
                    v-if="row.contact_person"
                  >
                    <b>Phone: </b> {{ row.contact_person.primary_phone }}
                  </p>
                </div>
              </td> -->
              <td class="simple-table-td" width="100">
                <div class="payment-listing-invoice">
                  <p class="m-0 custom-nowrap-ellipsis" v-on:click.stop>
                    <Barcode
                      :id="row.invoice.id"
                      :barcode="row.invoice.barcode"
                    ></Barcode>
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div class="payment-listing-mode">
                  <p class="m-0 custom-nowrap-ellipsis">
                    {{ row.payment_mode.text }}
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div class="payment-listing-amount">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Received: </b>
                    <span class="green--text">{{
                      $accountingJS.formatMoney(row.amount)
                    }}</span>
                    <em class="text--secondary d-block">( Paid )</em>
                  </p>
                </div>
              </td>
              <td>
                <TableActivity
                  v-if="!lodash.isEmpty(row.added_by)"
                  :data.sync="row"
                >
                  <template v-slot:display_name>
                    {{ row.added_by.display_name }}
                  </template>
                  <template v-slot:date_time>
                    {{ row.created_at }}
                  </template>
                  <template v-slot:format_date_time>
                    {{ formatDateTime(row.added_at) }}
                  </template>
                </TableActivity>
              </td>
            </tr>
          </template>
          <tr v-else-if="!dataLoading">
            <td colspan="6">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no payment at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
  </v-container>
  <!--end::Payment Internal Listing-->
</template>

<script>
import moment from "moment-timezone";
import { QUERY } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "payment-internal-list",
  data() {
    return {
      parent: 0,
      dates: [],
      statusList: [],
      invoiceList: [],
      dataRows: [],
      dataLoading: true,
      customerList: [],
      filter: {
        status: "all",
        invoice: "",
        search: null,
        payment_type: "",
      },
      timeout: null,
      timeoutLimit: 500,
      paymentTypeList: [],
    };
  },
  beforeMount() {
    this.paymentTypeList = [
      { text: "All", value: "" },
      { text: "Partial Paid", value: "0" },
      { text: "Full Paid", value: "1" },
    ];
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "customer",
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.parent = this.lodash.toSafeInteger(param.id);
        if (this.parent > 0) {
          this.getAllPayments();
        }
      },
    },
  },
  components: {
    Barcode,
    ListingTable,
    TableActivity,
  },
  methods: {
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getAllPayments();
    },
    getAllPayments() {
      const _this = this;

      let filter = {
        per_page: 10,
        current_page: 1,
        status: "all",
        search: _this.filter.search || undefined,
        invoice: _this.filter.invoice || undefined,
        payment_type: _this.filter.payment_type || undefined,
        dates: _this.dates || undefined,
        type: _this.type,
        type_id: _this.parent,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      } else {
        filter.dates = undefined;
      }

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "payment",
            data: filter,
          })
          .then(({ data }) => {
            _this.dataRows = data.rows;
            let invoiceList = data.invoice_list;
            invoiceList.unshift({ barcode: "All Invoice", id: "" });
            _this.invoiceList = invoiceList;
            _this.statusList = data.status_list;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
};
</script>
